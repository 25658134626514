define("discourse/plugins/moyun/discourse/routes/ig", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    titleToken() {
      return [this.modelFor("ig").get("name")];
    },
    async model(params) {
      return await this.store.find("group", params.name);
    },
    serialize(model) {
      return {
        name: model.get("name").toLowerCase()
      };
    },
    setupController(controller, model) {
      // console.log('bon',model);
      controller.set("model", model);
    },
    renderTemplate() {
      // Renders the template `../templates/notebook.hbs`
      this.render('groupdetail');
    }
  });
});