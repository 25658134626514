define("discourse/plugins/moyun/discourse/templates/register", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Modal::Logins></Modal::Logins>
  */
  {
    "id": "Pj8YJ8l7",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[],[]]]]]],[],false,[\"modal/logins\"]]",
    "moduleName": "discourse/plugins/moyun/discourse/templates/register.hbs",
    "isStrictMode": false
  });
});