define("discourse/plugins/moyun/discourse/controllers/subgroup", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _controller, _object, _service, _environment, _debounce, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("groups.extras.type_filters"), (_obj = {
    router: (0, _service.service)(),
    queryParams: ["order", "asc", "filter", "type"],
    order: null,
    asc: null,
    filter: "",
    type: null,
    groups: null,
    isLoading: false,
    // myStreetList: ['Hongqiao', 'Huacao', 'Huamu', 'Jingan', 'Sanlin', 'Xujing', 'Yichuan','Zhangjiang'],
    myStreetList: ['admins', 'moderators', 'staff', 'trust_level_0', 'trust_level_1', 'trust_level_2', 'trust_level_3', 'trust_level_4'],
    types(typeFilters) {
      const types = [];
      if (typeFilters) {
        typeFilters.forEach(type => types.push({
          id: type,
          name: _discourseI18n.default.t(`groups.index.${type}_groups`)
        }));
      }
      return types;
    },
    loadGroups(params) {
      this.set("isLoading", true);
      this.store.findAll("group", params).then(groups => {
        let newList = [];
        groups.content.forEach(groupItem => {
          if (!this.myStreetList.includes(groupItem.name)) {
            newList.push(groupItem);
          }
        });
        groups.content = newList;
        this.set("groups", groups);
      }).finally(() => {
        this.set("isLoading", false);
      });
    },
    onFilterChanged(filter) {
      (0, _debounce.default)(this, this._debouncedFilter, filter, _environment.INPUT_DELAY);
    },
    loadMore() {
      this.groups && this.groups.loadMore();
    },
    new() {
      this.router.transitionTo("groups.new");
    },
    _debouncedFilter(filter) {
      this.set("filter", filter);
    }
  }, (_applyDecoratedDescriptor(_obj, "types", [_dec], Object.getOwnPropertyDescriptor(_obj, "types"), _obj), _applyDecoratedDescriptor(_obj, "onFilterChanged", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onFilterChanged"), _obj), _applyDecoratedDescriptor(_obj, "loadMore", [_object.action], Object.getOwnPropertyDescriptor(_obj, "loadMore"), _obj), _applyDecoratedDescriptor(_obj, "new", [_object.action], Object.getOwnPropertyDescriptor(_obj, "new"), _obj)), _obj)));
});