define("discourse/plugins/moyun/discourse/components/my-faq", ["exports", "@ember/component", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- -----------------备份---------------- --}}
  {{!-- <div class="faq-wrapper">
    <h1 class="title">
      <div>Frequently asked</div>
      <div class="keywords">questions</div>
    </h1>
    <div class="supplement">Everything you need to know about CNS.</div>
    <div class="faq-content">
      {{#each this.faqList as |item index|}}
      <div class="faq-item">
        <div class="question">
          <div class="qusleft">
            <div class="sign">Q</div>
            <div>{{item.quest}}</div>
  
          </div>
          <div class="unfold" {{on "click" (fn this.clickAction index)}}>
            {{#if (eq this.currentQua index)}}
            <img src="/images/faq-close.png">
            {{else}}
            <img src="/images/faq-unfold.png">
            {{/if}}
          </div>
        </div>
        {{#if (eq this.currentQua index)}}
        <div class="answer">{{item.reply}}</div>
        {{/if}}
      </div>
      {{/each}}
  
    </div>
  </div> --}}
  
  <div class="faq-wrapper">
    {{!-- <h1 class="title">
      <div>Frequently asked</div>
      <div class="keywords">questions</div>
    </h1>
    <div class="supplement">Everything you need to know about CNS.</div> --}}
    <div class="faq-content">
      {{#each this.faqList as |item index|}}
      <div class="faq-item" {{on "click" (fn this.clickAction index)}}>
        <div class="question">
          <div class="qusleft">
            {{!-- <div class="sign">Q</div> --}}
            <div class="ask-line">
              <div>问：</div>
              <div>{{item.quest}}</div>
            </div>
  
          </div>
          <div class="unfold">
            {{#if (eq this.currentQua index)}}
            <img src="/images/arrow-up-about.png">
            {{else}}
            <img src="/images/arrow-down-about.png">
            {{/if}}
          </div>
        </div>
        {{#if (eq this.currentQua index)}}
        <div class="answer">
          <div>答：</div>
          <div>{{item.reply}}</div>
        </div>
        {{/if}}
      </div>
      {{/each}}
  
    </div>
  </div>
  */
  {
    "id": "56Z7qe+p",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"faq-wrapper\"],[12],[1,\"\\n\"],[1,\"  \"],[10,0],[14,0,\"faq-content\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"faqList\"]]],null]],null],null,[[[1,\"    \"],[11,0],[24,0,\"faq-item\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"clickAction\"]],[30,2]],null]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"question\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"qusleft\"],[12],[1,\"\\n\"],[1,\"          \"],[10,0],[14,0,\"ask-line\"],[12],[1,\"\\n            \"],[10,0],[12],[1,\"问：\"],[13],[1,\"\\n            \"],[10,0],[12],[1,[30,1,[\"quest\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"unfold\"],[12],[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"currentQua\"]],[30,2]],null],[[[1,\"          \"],[10,\"img\"],[14,\"src\",\"/images/arrow-up-about.png\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,\"img\"],[14,\"src\",\"/images/arrow-down-about.png\"],[12],[13],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"currentQua\"]],[30,2]],null],[[[1,\"      \"],[10,0],[14,0,\"answer\"],[12],[1,\"\\n        \"],[10,0],[12],[1,\"答：\"],[13],[1,\"\\n        \"],[10,0],[12],[1,[30,1,[\"reply\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"item\",\"index\"],false,[\"each\",\"-track-array\",\"on\",\"fn\",\"if\",\"eq\"]]",
    "moduleName": "discourse/plugins/moyun/discourse/components/my-faq.hbs",
    "isStrictMode": false
  });
  let MyFaq = _exports.default = (_class = class MyFaq extends _component.default {
    constructor() {
      super(...arguments);
      // faqList=[
      //   {
      //     quest:'What sets it apart from other virtual event platforms?',
      //     reply:'The platform assists with virtual event planning through customizabletemplates, attendee registration, agenda management, virtual venuesetup, speaker coordination, interactive features, and analytics. ltintegrates with other tools for a seamless experience.',
      //   },
      //   {
      //     quest:'How does the platform assist with virtual event planning?',
      //     reply:"The platform assists with virtual event planning through customizabletemplates, attendee registration, agenda management, virtual venuesetup, speaker coordination, interactive features, and analytics. ltintegrates with other tools for a seamless experience.",
      //   },
      //   {
      //     quest:"How can l start using it for my events?",
      //     reply:"The platform assists with virtual event planning through customizabletemplates, attendee registration, agenda management, virtual venuesetup, speaker coordination, interactive features, and analytics. ltintegrates with other tools for a seamless experience.",
      //   },
      //   {
      //     quest:"Can l customize my virtual events using the platform?",
      //     reply:"The platform assists with virtual event planning through customizabletemplates, attendee registration, agenda management, virtual venuesetup, speaker coordination, interactive features, and analytics. ltintegrates with other tools for a seamless experience.",
      //   },
      //   {
      //     quest:"Is there a trial period available?",
      //     reply:"The platform assists with virtual event planning through customizabletemplates, attendee registration, agenda management, virtual venuesetup, speaker coordination, interactive features, and analytics. ltintegrates with other tools for a seamless experience.",
      //   },
      //   {
      //     quest:"Is it possible to subscribe to the app annualy ?",
      //     reply:"The platform assists with virtual event planning through customizabletemplates, attendee registration, agenda management, virtual venuesetup, speaker coordination, interactive features, and analytics. ltintegrates with other tools for a seamless experience.",
      //   },
      //   {
      //     quest:"Is it possible to cancel my subscription ?",
      //     reply:"The platform assists with virtual event planning through customizabletemplates, attendee registration, agenda management, virtual venuesetup, speaker coordination, interactive features, and analytics. ltintegrates with other tools for a seamless experience.",
      //   },
      //   {
      //     quest:"How do l change my account mail ?",
      //     reply:"The platform assists with virtual event planning through customizabletemplates, attendee registration, agenda management, virtual venuesetup, speaker coordination, interactive features, and analytics. ltintegrates with other tools for a seamless experience.",
      //   },
      //   {
      //     quest:"How can l change my payment method ?",
      //     reply:"The platform assists with virtual event planning through customizabletemplates, attendee registration, agenda management, virtual venuesetup, speaker coordination, interactive features, and analytics. ltintegrates with other tools for a seamless experience.",
      //   },
      // ];
      _defineProperty(this, "faqList", [
      // {
      //   quest: '问：谁可以申请成为Community版主？',
      //   reply: '答：任何人都可以，只需要发送申请，我们会及时与您取得联系。',
      // },
      // {
      //   quest: '问：原City News Service用户可以直接登陆吗？ ',
      //   reply: '答：不止City News Service，包括Shine和Shanghai Daily网站用户都可以直接登陆。',
      // },
      // {
      //   quest: '问：一个人可以加入几个群组？',
      //   reply: '答：没有限制，您可以自由选择感兴趣的群组加入。',
      // },
      // {
      //   quest: '问：社区版块有什么福利活动吗？',
      //   reply: '答：我们社区栏目设有“积分制度”，用户可以积极互动获取积分，积极上榜。后续我们会上线“积分商城”会有更多玩法，敬请期待吧～',
      // },
      // {
      //   quest: '问：为什么我发布完帖子看不到？',
      //   reply: '答：所有帖子都有个审核机制，需要审核通过后，方可发布到前端。您也可以在个人页面，查看帖子发布的状态。',
      // },
      {
        quest: '谁可以申请成为Community版主？',
        reply: '任何人都可以，只需要发送申请，我们会及时与您取得联系。'
      }, {
        quest: '原City News Service用户可以直接登陆吗？ ',
        reply: '不止City News Service，包括Shine和Shanghai Daily网站用户都可以直接登陆。'
      }, {
        quest: '一个人可以加入几个群组？',
        reply: '没有限制，您可以自由选择感兴趣的群组加入。'
      }, {
        quest: '社区版块有什么福利活动吗？',
        reply: '我们社区栏目设有“积分制度”，用户可以积极互动获取积分，积极上榜。后续我们会上线“积分商城”会有更多玩法，敬请期待吧～'
      }, {
        quest: '为什么我发布完帖子看不到？',
        reply: '所有帖子都有个审核机制，需要审核通过后，方可发布到前端。您也可以在个人页面，查看帖子发布的状态。'
      }]);
      _initializerDefineProperty(this, "currentQua", _descriptor, this);
    }
    clickAction(param) {
      console.log('按钮被点击了！', param);
      if (param === this.currentQua) {
        this.currentQua = null;
      } else {
        this.currentQua = param;
      }
      console.log(this.currentQua);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentQua", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "clickAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clickAction"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MyFaq);
});