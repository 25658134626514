define("discourse/plugins/moyun/discourse/templates/userlogin", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Modal::CreateAccounts></Modal::CreateAccounts>
  */
  {
    "id": "SDDqFyWD",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[],[]]]]]],[],false,[\"modal/create-accounts\"]]",
    "moduleName": "discourse/plugins/moyun/discourse/templates/userlogin.hbs",
    "isStrictMode": false
  });
});