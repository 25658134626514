define("discourse/plugins/moyun/discourse/controllers/applystaff", ["exports", "@ember/controller", "jquery", "discourse/lib/ajax", "discourse-common/lib/debounce", "discourse-common/lib/get-url", "discourse-common/lib/icon-library", "discourse-i18n", "@glimmer/tracking", "discourse/lib/ajax-error", "@ember/object", "@ember/utils", "discourse-common/utils/decorators", "@ember/service"], function (_exports, _controller, _jquery, _ajax, _debounce, _getUrl, _iconLibrary, _discourseI18n, _tracking, _ajaxError, _object, _utils, _decorators, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.popupAutomaticMembershipAlert = popupAutomaticMembershipAlert;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function popupAutomaticMembershipAlert(group_id, email_domains) {
    if (!email_domains) {
      return;
    }
    const data = {};
    data.automatic_membership_email_domains = email_domains;
    if (group_id) {
      data.id = group_id;
    }
    (0, _ajax.ajax)(`/admin/groups/automatic_membership_count.json`, {
      type: "PUT",
      data
    }).then(result => {
      const count = result.user_count;
      if (count > 0) {
        this.dialog.alert(_discourseI18n.default.t("admin.groups.manage.membership.automatic_membership_user_count", {
          count
        }));
      }
    });
  }
  let FlairController = _exports.default = (_dec = (0, _decorators.default)("sectionName", "instr", "phoneTel", "userName"), _dec2 = (0, _decorators.default)("phoneTel", "forceValidationReason"), (_class = class FlairController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "sectionName", _descriptor2, this);
      _initializerDefineProperty(this, "instr", _descriptor3, this);
      _initializerDefineProperty(this, "phoneTel", _descriptor4, this);
      _initializerDefineProperty(this, "userName", _descriptor5, this);
      _initializerDefineProperty(this, "saving", _descriptor6, this);
      _initializerDefineProperty(this, "model", _descriptor7, this);
    }
    get demoAvatarUrl() {
      return (0, _getUrl.default)('/images/avatar.png');
    }
    get flairPreviewIcon() {
      return this.model.flair_type === 'icon';
    }
    get flairPreviewIconUrl() {
      return this.model.flair_icon ? (0, _iconLibrary.convertIconClass)(this.model.flair_icon) : '';
    }
    get flairPreviewImage() {
      return this.model.flair_type === 'image';
    }
    get flairImageUrl() {
      return this.model.flair_url && this.model.flair_url.includes('/') ? this.model.flair_url : null;
    }
    get flairPreviewLabel() {
      const key = this.flairPreviewImage ? 'image' : 'icon';
      return _discourseI18n.default.t(`groups.flair_preview_${key}`);
    }
    _loadIcon() {
      if (!this.model.flair_icon) {
        return;
      }
      const icon = (0, _iconLibrary.convertIconClass)(this.model.flair_icon),
        c = '#svg-sprites',
        h = 'ajax-icon-holder',
        singleIconEl = `${c} .${h}`;
      if (!icon) {
        return;
      }
      if (!(0, _jquery.default)(`${c} symbol#${icon}`).length) {
        (0, _ajax.ajax)(`/svg-sprite/search/${icon}`).then(data => {
          if ((0, _jquery.default)(singleIconEl).length === 0) {
            (0, _jquery.default)(c).append(`<div class="${h}">`);
          }
          (0, _jquery.default)(singleIconEl).html(`<svg xmlns='http://www.w3.org/2000/svg' style='display: none;'>${data}</svg>`);
        });
      }
    }
    _loadSVGIcon() {
      if (this.model.flair_icon) {
        (0, _debounce.default)(this, this._loadIcon, 1000);
      }
    }
    setFlairImage(event) {
      const upload = event.target.files[0];
      if (upload) {
        const fileUrl = URL.createObjectURL(upload);
        this.model.flair_url = (0, _getUrl.default)(fileUrl);
        this.model.flair_upload_id = upload.id;
      }
    }
    removeFlairImage() {
      this.model.flair_url = null;
      this.model.flair_upload_id = null;
    }
    save() {
      this.set("saving", true);
      // 提交前的赋值
      this.set("model.name", this.sectionName);
      console.log(this.model, '111------');
      const group = this.model;
      popupAutomaticMembershipAlert(group.id, group.automatic_membership_email_domains);
      group.create().then(() => {
        this.router.transitionTo("group.members", group.name);
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("saving", false));
    }
    updateSavingState() {
      if (!(0, _utils.isEmpty)(this.sectionName) && !(0, _utils.isEmpty)(this.instr) && !(0, _utils.isEmpty)(this.phoneTel) && !(0, _utils.isEmpty)(this.userName)) {
        this.saving = false;
      } else {
        this.saving = true;
      }
    }

    // 手机号的校验
    phoneNumberValidation(phoneNumber, forceValidationReason) {
      const failedAttrs = {
        failed: true,
        ok: false,
        element: document.querySelector("#new-account-phone")
      };

      // If blank, fail without a reason
      if ((0, _utils.isEmpty)(phoneNumber)) {
        return _object.default.create(Object.assign(failedAttrs, {
          message: "user phone_number required",
          reason: forceValidationReason ? "user phone_number required" : null
        }));
      }
      if (!phoneNumber.match(/^1[3456789]\d{9}$/)) {
        return _object.default.create(Object.assign(failedAttrs, {
          reason: "user phone_number invalid"
        }));
      }
      return _object.default.create({
        ok: true,
        reason: "phoneNumber authenticated"
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sectionName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "instr", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "phoneTel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "userName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "model", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        flair_type: "icon",
        flair_icon: "some-icon",
        flair_url: ""
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_loadSVGIcon", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_loadSVGIcon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setFlairImage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setFlairImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeFlairImage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeFlairImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSavingState", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateSavingState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "phoneNumberValidation", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "phoneNumberValidation"), _class.prototype)), _class));
});