define("discourse/plugins/moyun/discourse/controllers/banner-manager", ["exports", "@ember/controller", "discourse/lib/ajax", "discourse-i18n", "@glimmer/tracking", "discourse-common/utils/decorators", "@ember/service", "@ember/object", "discourse/plugins/moyun/discourse/components/modal/request-banner-form"], function (_exports, _controller, _ajax, _discourseI18n, _tracking, _decorators, _service, _object, _requestBannerForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let BannerManagerController = _exports.default = (_class = class BannerManagerController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _initializerDefineProperty(this, "dialog", _descriptor2, this);
    }
    showRequestBannerForm(param) {
      if (param !== undefined) {
        this.modal.show(_requestBannerForm.default, {
          model: param
        });
      } else {
        this.modal.show(_requestBannerForm.default, {
          model: {}
        });
      }
    }
    deleteBanner(param) {
      return this.dialog.confirm({
        message: 'Are you sure you want to delete this banner?',
        didConfirm: async () => {
          const res = await (0, _ajax.ajax)(`/moyun/home_banners/${param.id}`, {
            type: "DELETE"
          });
          window.location.reload();
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showRequestBannerForm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showRequestBannerForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteBanner", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteBanner"), _class.prototype)), _class);
});