define("discourse/plugins/moyun/discourse/routes/ig-topics copy", ["exports", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    titleToken() {
      return _discourseI18n.default.t(`groups.topics`);
    },
    model() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.store.findFiltered("topicList", {
        filter: `topics/groups/${this.modelFor("ig").get("name")}`,
        params
      });
    }
  });
});