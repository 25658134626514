define("discourse/plugins/moyun/discourse/routes/userlogin", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    renderTemplate() {
      // Renders the template `../templates/notebook.hbs`
      this.render('userlogin');
    },
    model() {
      // login 移动端 左上角logo隐藏
      console.log('login page');
      const element = document.querySelector("body");
      // 添加类名
      element.classList.add("login-page-xn-body");
    }
  });
});