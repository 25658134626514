define("discourse/plugins/moyun/discourse/templates/admin-plugins-moyun", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    
  <div class="swiper-item">
    swiper-item
  </div>
  
  */
  {
    "id": "wRJo4SdR",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"swiper-item\"],[12],[1,\"\\n  swiper-item\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "discourse/plugins/moyun/discourse/templates/admin-plugins-moyun.hbs",
    "isStrictMode": false
  });
});