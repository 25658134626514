define("discourse/plugins/moyun/discourse/routes/subgroup", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Route for the path `/notebook` as defined in `../notebook-route-map.js.es6`.
   */
  var _default = _exports.default = _discourse.default.extend({
    queryParams: {
      order: {
        refreshModel: true,
        replace: true
      },
      asc: {
        refreshModel: true,
        replace: true
      },
      filter: {
        refreshModel: true
      },
      type: {
        refreshModel: true,
        replace: true
      },
      username: {
        refreshModel: true
      }
    },
    titleToken() {
      return I18n.t("groups.index.title");
    },
    model(params) {
      // 在这里直接返回params，这样它们可以在setupController中使用
      return params;
    },
    setupController(controller, params) {
      // 调用控制器的loadGroups方法，并传入params
      // 这样做可以在控制器中处理数据加载和转换逻辑
      controller.loadGroups(params);
    },
    renderTemplate() {
      // Renders the template `../templates/notebook.hbs`
      this.render('subgroup');
    }
  });
});