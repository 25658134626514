define("discourse/plugins/moyun/discourse/components/modal/cropimagemodel", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/mixins/uppy-upload", "discourse/lib/ajax", "message-bus-client", "@uppy/core", "@uppy/xhr-upload", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _uppyUpload, _ajax, _messageBusClient, _core, _xhrUpload, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- <DModal @title={{this.title}} @closeModal={{@closeModal}} @inline={{@inline}} class="banner-edit-model"> --}}
  <DModal @title={{this.title}} @closeModal={{this.closeModal}} @inline={{@inline}} class="banner-crop-model">
    <:body>
  <div id="moyunCropWrapper">
    <div class="cropper-wrapper">
      <div class="imgcrop-container" {{did-insert this.initializeCropper}}>
        <img class="cropImg" src={{@model.bannerImg}} alt="" />
      </div>
      {{!-- <div class="imgcrop-container">
        <img class="aftercrop" src={{this.afterImg}} alt="" />
      </div> --}}
    </div>
  </div>
  </:body>
    <:footer>
      <DButton @action={{this.sureSava}} @label="crop" class="btn-primary" />
      <DModalCancel @close={{this.closeModal}} />
      <ConditionalLoadingSpinner @size="small" @condition={{this.loading}} />
    </:footer>
  </DModal>
  */
  {
    "id": "rxnVSEk7",
    "block": "[[[8,[39,0],[[24,0,\"banner-crop-model\"]],[[\"@title\",\"@closeModal\",\"@inline\"],[[30,0,[\"title\"]],[30,0,[\"closeModal\"]],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[10,0],[14,1,\"moyunCropWrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"cropper-wrapper\"],[12],[1,\"\\n    \"],[11,0],[24,0,\"imgcrop-container\"],[4,[38,1],[[30,0,[\"initializeCropper\"]]],null],[12],[1,\"\\n      \"],[10,\"img\"],[14,0,\"cropImg\"],[15,\"src\",[30,2,[\"bannerImg\"]]],[14,\"alt\",\"\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[]],[[[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,0,[\"sureSava\"]],\"crop\"]],null],[1,\"\\n    \"],[8,[39,3],null,[[\"@close\"],[[30,0,[\"closeModal\"]]]],null],[1,\"\\n    \"],[8,[39,4],null,[[\"@size\",\"@condition\"],[\"small\",[30,0,[\"loading\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"@inline\",\"@model\"],false,[\"d-modal\",\"did-insert\",\"d-button\",\"d-modal-cancel\",\"conditional-loading-spinner\"]]",
    "moduleName": "discourse/plugins/moyun/discourse/components/modal/cropimagemodel.hbs",
    "isStrictMode": false
  });
  let Cropimagemodel = _exports.default = (_class = class Cropimagemodel extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "afterImg", _descriptor, this);
      _initializerDefineProperty(this, "myCropper", _descriptor2, this);
      _initializerDefineProperty(this, "loading", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _defineProperty(this, "_uppyInstance", null);
      _defineProperty(this, "dataURLtoFile", (dataURI, type) => {
        var binary = atob(dataURI.split(',')[1]);
        var array = [];
        for (var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {
          type: type
        });
      });
    }
    get title() {
      console.log('09', this);
      return 'Crop';
    }
    closeModal() {
      // this.args.onClose 可能是从 modal 服务传递的一个关闭当前 modal 的回调
      if (this.args.model.onClose) {
        this.args.model.onClose();
      }
    }
    initializeCropper() {
      const imageElement = document.querySelector('.cropImg');
      // console.log('000',$('.cropImg'),imageElement);

      if (imageElement) {
        this.myCropper = new Cropper(imageElement, {
          viewMode: 1,
          dragMode: 'none',
          aspectRatio: 2.7 / 1,
          initialAspectRatio: 1,
          // preview: '.afterCropper',
          autoCropArea: 0.6,
          zoomOnWheel: false
        });
      }
    }
    async sureSava() {
      this.afterImg = this.myCropper.getCroppedCanvas({
        imageSmoothingQuality: 'high'
      }).toDataURL('image/png');
      let formData = new FormData();
      let blob = this.dataURLtoFile(this.afterImg, 'image/jpeg');
      let fileOfBlob = new File([blob], new Date() + '.jpg');
      formData.append('file', fileOfBlob);
      formData.append('upload_type', 'banner_image');
      formData.append('name', `banner_image.png`);
      formData.append('type', 'image/png');
      console.log('343-formData', formData);
      try {
        const result = await (0, _ajax.ajax)(`/uploads.json?client_id=${_messageBusClient.default.clientId}`, {
          method: 'POST',
          data: formData,
          processData: false,
          contentType: false
          // headers: {
          //   'Content-Type': 'application/json',
          // },
        });
        if (this.args.model.onUpdateCropImg) {
          this.args.model.onUpdateCropImg(result.url);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "afterImg", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "myCropper", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initializeCropper", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "initializeCropper"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sureSava", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sureSava"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Cropimagemodel);
});