define("discourse/plugins/moyun/discourse/components/advertise-swiper/adswiper", ["exports", "@ember/component", "@ember/service", "discourse/components/d-button", "@ember/object", "@ember/template-factory"], function (_exports, _component, _service, _dButton, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _AdvertiseSwiper;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class AdvertiseSwiper extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "swiperList", [{
        title: '标题11',
        pic: "https://obj.citynewsservice.cn/community/quiz_adv.jpg",
        linkTo: "https://www.citynewsservice.cn/quiz?qid=8cc1e682-0e9e-11ef-bd6b-89f43be51932"
      }]);
    }
    didInsertElement() {
      this._super(...arguments);
      const swiper1 = new Swiper('.adswiper', {
        //modules: [ Pagination, Autoplay, Navigation ],
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          autoplay: true
        }
      });
    }
  }
  _exports.default = AdvertiseSwiper;
  _AdvertiseSwiper = AdvertiseSwiper;
  (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="adswiper-container">
        <div class="swiper adswiper">
          <div class="swiper-wrapper">
            {{#each this.swiperList as | item |}}
              <div class="swiper-slide">
                <a href="/weekly-quiz" target="_blank" rel="noopener noreferrer" style="cursor: pointer;display:block;">
                  <img src={{item.pic}} alt={{item.title}}/>
                </a>
              </div>
            {{/each}}
          </div>
          <div class="swiper-pagination"></div>
          <div class="adSwiper-title"> Saudi Arabia will hold a week-long cultural and tourism promotion event on the Bund in Shanghai. </div>
        </div>
      </div>
    
  */
  {
    "id": "ebyP1Mht",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"adswiper-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"swiper adswiper\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"swiper-wrapper\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"swiperList\"]]],null]],null],null,[[[1,\"            \"],[10,0],[14,0,\"swiper-slide\"],[12],[1,\"\\n              \"],[10,3],[14,6,\"/weekly-quiz\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,5,\"cursor: pointer;display:block;\"],[12],[1,\"\\n                \"],[10,\"img\"],[15,\"src\",[30,1,[\"pic\"]]],[15,\"alt\",[30,1,[\"title\"]]],[12],[13],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"swiper-pagination\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"adSwiper-title\"],[12],[1,\" Saudi Arabia will hold a week-long cultural and tourism promotion event on the Bund in Shanghai. \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"item\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/moyun/discourse/components/advertise-swiper/adswiper.js",
    "isStrictMode": true
  }), _AdvertiseSwiper);
});