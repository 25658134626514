define("discourse/plugins/moyun/discourse/controllers/advertising", ["exports", "@ember/controller", "@ember/service", "@ember/object", "discourse-common/utils/decorators", "discourse-common/lib/get-url", "@ember/utils", "discourse/lib/ajax"], function (_exports, _controller, _service, _object, _decorators, _getUrl, _utils, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AdvertisingController = _exports.default = (_dec = (0, _decorators.default)("flair_url"), (_class = class AdvertisingController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "dialog", _descriptor3, this);
      _defineProperty(this, "title", null);
      _defineProperty(this, "advertisingvalue", null);
      _defineProperty(this, "url", null);
      _defineProperty(this, "saving", true);
      _defineProperty(this, "image", null);
      _defineProperty(this, "saveloading", false);
    }
    advertisingurl(flairUrl) {
      return flairUrl && flairUrl.includes("/") ? flairUrl : null;
    }
    advertisinguploadImage(upload) {
      let urls = `![${upload.file_name}|${upload.width}x${upload.height}](${upload.short_url})`;
      this.set('image', urls);
      this.setProperties({
        flair_url: (0, _getUrl.default)(upload.url),
        flair_upload_id: upload.id
      });
    }
    advertisingremoveImage() {
      this.setProperties({
        flair_url: null,
        flair_upload_id: null
      });
    }
    save() {
      this.set('saveloading', true);
      let markdownContent = `
  ### Advertising Title
  ${this.title}

  ### Target Url
  ${this.url}

  ### Upload KV
  ${this.image}

  ### Application Readon
  ${this.advertisingvalue}
  `;
      const data = {};
      data.raw = markdownContent;
      // 获取当前时间戳
      let currentTimestamp = new Date().getTime();
      // 拼接姓名和时间戳
      data.title = this.currentUser.username + "-" + currentTimestamp;
      // data.title='Homepage Advertisement Application'
      data.category = "11";
      this.set("saving", true);
      (0, _ajax.ajax)('/posts', {
        type: 'POST',
        data: data
      }).then(res => {
        this.set('saveloading', false);
        this.dialog.alert({
          message: 'Advertising requires application.'
        });
        this.reset();
      }).finally(() => {
        this.set('saveloading', false);
      });
    }
    updateSavingState() {
      if (!(0, _utils.isEmpty)(this.title) && !(0, _utils.isEmpty)(this.url) && !(0, _utils.isEmpty)(this.advertisingvalue)) {
        this.set('saving', false);
      } else {
        this.set('saving', true);
      }
    }
    reset() {
      this.set('title', null);
      this.set('url', null);
      this.set('image', null);
      this.set('advertisingvalue', null);
      this.advertisingremoveImage();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "advertisingurl", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "advertisingurl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "advertisinguploadImage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "advertisinguploadImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "advertisingremoveImage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "advertisingremoveImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSavingState", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSavingState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype)), _class));
});