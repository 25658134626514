define("discourse/plugins/moyun/discourse/templates/application-page", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    
  
  <h1>Application of group</h1>
  
  <div>
    <LoadMore @selector=".paginated-topics-list .topic-list tr" @action={{action "loadMore" }}
      class="paginated-topics-list">
      <BasicTopicList @topicList={{this.model}} @showPosters={{true}} />
      <ConditionalLoadingSpinner @condition={{this.model.loadingMore}} />
    </LoadMore>
  </div>
  */
  {
    "id": "VtCmq2+S",
    "block": "[[[1,\"\\n\\n\"],[10,\"h1\"],[12],[1,\"Application of group\"],[13],[1,\"\\n\\n\"],[10,0],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"paginated-topics-list\"]],[[\"@selector\",\"@action\"],[\".paginated-topics-list .topic-list tr\",[28,[37,1],[[30,0],\"loadMore\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@topicList\",\"@showPosters\"],[[30,0,[\"model\"]],true]],null],[1,\"\\n    \"],[8,[39,3],null,[[\"@condition\"],[[30,0,[\"model\",\"loadingMore\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"load-more\",\"action\",\"basic-topic-list\",\"conditional-loading-spinner\"]]",
    "moduleName": "discourse/plugins/moyun/discourse/templates/application-page.hbs",
    "isStrictMode": false
  });
});