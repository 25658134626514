define("discourse/plugins/moyun/discourse/components/center-swiper/cnswiper", ["exports", "@ember/component", "@ember/service", "discourse/components/d-button", "@ember/object", "@ember/template-factory"], function (_exports, _component, _service, _dButton, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _CenterSwiper;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class CenterSwiper extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "swiperList", [{
        title: '标题11',
        pic: "https://obj.shine.cn/files/2024/04/18/7471e3ad-2b04-4dfe-9818-4b221ab54140_0.jpg"
      }, {
        title: '标题22',
        pic: 'https://obj.shine.cn/files/2024/05/01/84723faf-2aa5-4191-bbce-4cb167d7005b_0.jpg'
      }, {
        title: '标题33',
        pic: 'https://obj.shine.cn/files/2024/06/09/8893221f-e17b-4640-8037-fe5a99b77664_0.jpg'
      }, {
        title: '标题44',
        pic: 'https://obj.shine.cn/files/2024/04/26/abf475a2-b392-423c-b193-8f0db598f033_0.jpg'
      }]);
    }
    didInsertElement() {
      this._super(...arguments);
      const swiper1 = new Swiper('.cnswiper', {
        //modules: [ Pagination, Autoplay, EffectCoverflow ],
        loop: true,
        effect: 'coverflow',
        slidesPerView: 1.5,
        centeredSlides: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 160,
          depth: 200,
          modifier: 2,
          slideShadows: false
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          autoplay: true
        }
      });
    }
  }
  _exports.default = CenterSwiper;
  _CenterSwiper = CenterSwiper;
  (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="cnswiper-container">
        <div class="swiper cnswiper">
            <div class="swiper-wrapper">
              {{#each this.swiperList as | item |}}
                <div class="swiper-slide">
                  <img src={{item.pic}}/>
                </div>
              {{/each}}
            </div>
            <div class="swiper-pagination"></div>
            <div class="adSwiper-title"> Saudi Arabia will hold a week-long cultural and tourism promotion event on the Bund in Shanghai. </div>
        </div>
      </div>
    
  */
  {
    "id": "S/40wRYL",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"cnswiper-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"swiper cnswiper\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"swiper-wrapper\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"swiperList\"]]],null]],null],null,[[[1,\"              \"],[10,0],[14,0,\"swiper-slide\"],[12],[1,\"\\n                \"],[10,\"img\"],[15,\"src\",[30,1,[\"pic\"]]],[12],[13],[1,\"\\n              \"],[13],[1,\"\\n\"]],[1]],null],[1,\"          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"swiper-pagination\"],[12],[13],[1,\"\\n          \"],[10,0],[14,0,\"adSwiper-title\"],[12],[1,\" Saudi Arabia will hold a week-long cultural and tourism promotion event on the Bund in Shanghai. \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"item\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/moyun/discourse/components/center-swiper/cnswiper.js",
    "isStrictMode": true
  }), _CenterSwiper);
});