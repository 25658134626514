define("discourse/plugins/moyun/discourse/controllers/application-page", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    actions: {
      loadMore() {
        this.model.loadMore();
      }
    }
  });
});