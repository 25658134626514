define("discourse/plugins/moyun/discourse/routes/applystaff", ["exports", "discourse/routes/discourse", "discourse/models/group"], function (_exports, _discourse, _group) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Route for the path `/notebook` as defined in `../notebook-route-map.js.es6`.
   */
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _group.default.create({
        automatic: false,
        visibility_level: 0,
        can_admin_group: true
      });
    },
    setupController(controller, model) {
      controller.set("model", model);
    },
    renderTemplate() {
      // Renders the template `../templates/notebook.hbs`
      this.render('apply');
    }
  });
});