define("discourse/plugins/moyun/discourse/routes/usergroups", ["exports", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class GroupsIndexRoute extends _discourse.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queryParams", {
        order: {
          refreshModel: true,
          replace: true
        },
        asc: {
          refreshModel: true,
          replace: true
        },
        filter: {
          refreshModel: true
        },
        type: {
          refreshModel: true,
          replace: true
        },
        username: {
          refreshModel: true
        }
      });
    }
    titleToken() {
      return _discourseI18n.default.t("groups.index.title");
    }
    model(params) {
      // console.log('筛选groups', params);
      return params;
    }
    setupController(controller, params) {
      controller.loadGroups(params);
    }
  }
  _exports.default = GroupsIndexRoute;
});