define("discourse/plugins/moyun/discourse/components/modal/Encryption", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Encryption = void 0;
  class PasspordUtility {
    // static encode_user_password_for_login({ password, user_info}){
    //   let passstrencode = Encryption.encryption(password, 100);
    //   passstrencode = Encryption.md5_hexdigest({ str: `${passstrencode}|${user_info.salt}` });
    //   let sessiontoken = user_info.sessiontoken;
    //   let code = `${appid + passstrencode + sessiontoken}`;
    //   let password_encoded = Encryption.md5_hexdigest({ str: code });
    //   return password_encoded;
    // }

    ///password为用户的密码明文
    static generate_authorcode(password) {
      let passstrencode = Encryption.encryption(password, 100);
      return passstrencode;
    }
  }
  _exports.default = PasspordUtility;
  class Encryption {
    static encryption(val, degree) {
      let _degree = degree || 100;
      let str = val;
      for (let num = 0; num < _degree; num += 1) {
        str = this.md5_hexdigest({
          str: str + num
        });
      }
      return str;
    }
    static md5_hexdigest(_ref) {
      let {
        str,
        binary = false
      } = _ref;
      let md = forge.md.md5.create();
      md.update(str);
      return md.digest().toHex();
      //return CryptoJS.MD5(str);
      ///js如何生成md5,我们使用的是forge安全模块.
      ///具体用那个md5库，自行斟酌
      ///如果binary为true，需要将hex转换成二进制格式. 目前针对密码这块，应该binary都是为false.
    }
  }
  _exports.Encryption = Encryption;
});